import get from 'lodash.get'
import { stateList } from '../state-list'

const monthsMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

export function formatDate (dateStr, formatByShortDate = false) {
  const [year, month, day] = dateStr.split('-')
  return {
    year,
    month: formatByShortDate ? month : monthsMap[Number(month)],
    day: formatByShortDate ? day : Number(day)
  }
}

/**
 * Retrieve a value from a map.
 * @param map
 * @param key
 * @param defaultValue
 * @returns {*}
 */
export function getFromMap (map, key, defaultValue = null) {
  if (Object.hasOwnProperty.call(map, key)) {
    return map[key]
  }

  if (defaultValue !== null) {
    return defaultValue
  }

  throw new Error(`Invalid key in map: ${key}`)
}

/**
 * Generate a random hash.
 * @returns {string}
 */
export function randomHash () {
  return Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7)
}

/**
 * Prefix an asset with the path to our CDN.
 * @param {string} assetSrc
 * @returns {string}
 */
export function cdn (assetSrc) {
  if (!assetSrc) return ''
  // Ignore URLs that already specify a schema
  if (assetSrc.startsWith('http')) {
    return assetSrc
  }
  return `${process.env.GATSBY_IMAGE_PREFIX}${assetSrc}`
}

/**
 * Throttle a function call. https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
 * @param {number} delay
 * @param {function (...[*]): *} fn
 * @returns {function(...[*]): *}
 */
export function throttle (delay, fn) {
  let lastCall = 0
  return function (...args) {
    const now = (new Date()).getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

/**
 * Formats weight limits.
 * @param {number} minWeight
 * @param {number} maxWeight
 * @param {string} uom
 * @returns {string}
 */
export function formatMinMaxWeightLimits (minWeight, maxWeight, uom) {
  const weightStr = `${maxWeight} ${uom}`

  return minWeight ? `${minWeight} - ${weightStr}` : weightStr
}

/**
 * Helps prevent undefined from sneaking into the class names, should be used anytime class names have the potential to be undefined such as props.
 * @param {...string} classNames
 * @return {string}
 */
export function safeClassName (...classNames) {
  return classNames.reduce((accumulator, className) => {
    if (!className) return accumulator
    return accumulator ? `${accumulator} ${className}` : className
  }, '')
}

/**
 * Insert an html object after a specified object
 * @param {object} newNode
 * @param {object} referenceNode
 */
export function insertAfter (newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
}

/**
 * Remove a thing from the DOM
 * @param {object} node
 */
export function removeSelf (node) {
  node.parentNode.removeChild(node)
}

/**
 * Converts strings to lower-kebab-case
 * @param {string} string
 * @returns {string}
 */
export function toLowerKebabCase (string) {
  return String(string).toLowerCase().replace(/\s/g, '-')
}

/**
 * Sort helper for sorting ascending
 * @param {string|number} a
 * @param {string|number} b
 */
const sortASC = (a, b) => {
  if (a < b) {
    return -1
  }

  if (a > b) {
    return 1
  }

  return 0
}

/**
 * Sort helper for sorting descending
 * @param {string|number} a
 * @param {string|number} b
 */
const sortDESC = (a, b) => {
  if (a < b) {
    return 1
  }

  if (a > b) {
    return -1
  }

  return 0
}

/**
 * Sort array with ascending or descending order.
 *
 * @param {array} array
 * @param {string} path
 * @param {'ASC'|'DESC'} order
 */
export const sort = (array, path, order = 'ASC') => {
  return array.sort((a, b) => {
    let leftVal = get(a, path)
    let rightVal = get(b, path)

    if (!leftVal || !rightVal) {
      throw Error(`Can't compare ${leftVal} with ${rightVal}`)
    }

    if (isNaN(leftVal)) {
      leftVal = String(leftVal).toLowerCase()
    }

    if (isNaN(rightVal)) {
      rightVal = String(rightVal).toLowerCase()
    }

    const comparators = {
      ASC: sortASC,
      DESC: sortDESC
    }

    return comparators[order.toUpperCase()](leftVal, rightVal)
  })
}

export function toEstTime (dateString) {
  try {
    const dateObj = new Date(dateString)
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric'
    }).format(dateObj)
  } catch (error) {
    console.error(`toEstTime: Could not parse dateString ${dateString}`)
    return 'N/A'
  }
}

// Because address is a free field in the CMS, we have to account for both full state name and abbreviation (Cleveland had Ohio in the address and
// I'd wager that isn't going to always be the case to spell the state out, I'm looking you, Dakotas).
function splitStreetFromCity (address, city, state) {
  if (address.indexOf(` ${city}, ${stateList[state]}`) !== -1) {
    return address.split(` ${city}, ${stateList[state]}`)[0]
  }

  return address.split(` ${city}, ${state}`)[0]
}

export function formatAddressForStructuredData ({ state, name }, address) {
  const streetAddress = splitStreetFromCity(address, name, state)
  const zip = address.slice(-5)

  return [streetAddress, name, state, isNaN(zip) ? '' : zip]
}

export function isGoogleAnalyticsReady () {
  return window && window.ga
}

export const uniqueFromArray = (data) => {
  const uniqueSet = new Set()
  for (const dataObj of data) {
    if (!uniqueSet.has(dataObj.id)) {
      uniqueSet.add(dataObj)
    }
  }
  return Array.from(uniqueSet)
}

export function convertHour (hour) {
  return hour > 12 ? hour - 12 : hour
}

// from https://gomakethings.com/how-to-get-the-value-of-a-querystring-with-native-javascript/
export function getQueryString (field, url = undefined) {
  const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i')
  const result = reg.exec(url || window.location.href)
  return result ? result[1] : null
}

export function timeStamp () {
  // Get local time as ISO string with offset at the end
  const now = new Date()
  const tzo = -now.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = function (num) {
    const norm = Math.abs(Math.floor(num))
    return (norm < 10 ? '0' : '') + norm
  }
  /* eslint-disable operator-linebreak */
  return now.getFullYear()
    + '-' + pad(now.getMonth() + 1)
    + '-' + pad(now.getDate())
    + 'T' + pad(now.getHours())
    + ':' + pad(now.getMinutes())
    + ':' + pad(now.getSeconds())
    + '.' + pad(now.getMilliseconds())
    + dif + pad(tzo / 60)
    + ':' + pad(tzo % 60)
}

export const getTrustPilotScoreIcon = (score) => {
  if (typeof score !== 'number') return ''
  if (score <= 3.5) {
    return 'trustpilot-rating-3-half-stars'
  } else if (score > 3.5 && score <= 4.0) {
    return 'trustpilot-rating-4-stars'
  } else if (score > 4.0 && score < 5.0) {
    return 'trustpilot-rating-4-half-stars'
  } else if (score === 5.0) {
    return 'trustpilot-rating-5-stars'
  }
}

export const isCallOrEmailLink = (url) => {
  return url.startsWith('tel:') || url.startsWith('mailto:')
}

export const isRelativeUrl = (url) => {
  return url.startsWith('/')
}

export const isExternalUrl = (url) => {
  if (url === '' || url.startsWith('#')) return false
  const absoluteUrl = isRelativeUrl(url) ? process.env.GATSBY_PRODUCTION_SITE_URL + url : url
  return absoluteUrl.indexOf('dumpsters.com') === -1 && !isCallOrEmailLink(absoluteUrl)
}

export function convertHolidaysToDateObjs (holidays) {
  return holidays.map(holidayDate => {
    // Because why would Safari know how to do dates like everyone else?
    const [date] = holidayDate.start.split('T')
    const [yyyy, mm, dd] = date.split('-')
    return new Date(yyyy, parseInt(mm, 10) - 1, dd)
  })
}

export const getKeyByValue = (value, object) => {
  return Object.keys(object).find(key => object[key] === value)
}

/**
 * Conditionally wrap elements with another
 * Wrap should be formatted like: wrap={children => <a href="/">{children}</a>}
 * Copied from: https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f
 *
 * @param {boolean} condition
 * @param {function} wrap
 * @param {element} children
 */
export const ConditionalWrap = ({ condition, wrap, children }) => condition ? wrap(children) : children

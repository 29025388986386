export function quickLinks (document, appEventEmitter) {
  function calculateScrollDistance (offsetTop) {
    const header = document.querySelector('header')
    const mobileNavCTA = document.querySelector('[data-js-nav-cta]')
    const isMobileNavCTAVisible = mobileNavCTA && window.getComputedStyle(mobileNavCTA).display !== 'none'

    if (header) {
      offsetTop -= header.clientHeight
    }

    if (isMobileNavCTAVisible) {
      offsetTop -= mobileNavCTA.clientHeight
    }

    return offsetTop
  }

  function scrollToElement (id) {
    const element = document.querySelector(id)

    if (element) {
      // We need to push scrolling to the end of callstack
      setTimeout(window.scrollTo.bind(null, 0, calculateScrollDistance(element.offsetTop), 0))
    }
  }

  function onHashChange () {
    const { hash } = window.location

    if (hash) {
      scrollToElement(hash)
    }
  }

  window.addEventListener('load', onHashChange, false)
  window.addEventListener('popstate', onHashChange, false)
}

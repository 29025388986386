import { cdn } from './utilities'
/**
 * cta-config-swap swaps out all CTAs depending on the configuration set in SCMS
 */
export function ctaConfigSwap (document, appEventEmitter) {
  const DURING_HOURS = 0
  const AFTER_HOURS = 1
  const allModules = document.querySelectorAll('section[data-js-module-name]')
  const header = document.querySelector('[data-js-header-ctas]')
  const nav = document.querySelectorAll('nav')
  const onscrollCTA = document.querySelector('[data-js-nav-cta]')
  const mainElement = document.querySelector('main')
  const pagePhoneNumber = mainElement && mainElement.dataset.jsPagePhoneNumber
  let ctaConfigMap = {}

  appEventEmitter.addEventListener('cta-config', handleCTAConfigEvent)

  function replacePhonePlaceholder (string) { return string.replace('[phonenumber]', pagePhoneNumber) }

  function handleCTAConfigEvent ({ detail }) {
    if (detail.findCTAConfigurationByURL.length === 0) {
      // If page has no configs, return early as no changes are needed
      return
    }
    ctaConfigMap = detail.findCTAConfigurationByURL.reduce((acc, cur) => {
      acc[cur.moduleIDPrefix] = JSON.parse(cur.ctaConfiguration)
      return acc
    }, {})

    if (ctaConfigMap.default) {
      !ctaConfigMap.header && findCTAs(header, ctaConfigMap.default)
      nav.forEach(element => findCTAs(element, ctaConfigMap.default))
    }

    ctaConfigMap.header && findCTAs(header, ctaConfigMap.header)
    ctaConfigMap.onscroll && findCTAs(onscrollCTA, ctaConfigMap.onscroll)
    allModules.forEach(module => updateModuleCTAs(module))
  }

  function findCTAs (element, ctaConfig) {
    element.querySelectorAll('[data-js-call-center-open]:not([data-js-cta-swap-exclude])').forEach((ctaWrapper) => {
      const replaceAll = Object.hasOwnProperty.call(ctaWrapper.dataset, 'jsCtaReplaceAll')
      updateCTA(ctaWrapper.querySelector('a'), ctaWrapper, replaceAll, ctaConfig, DURING_HOURS)
    })
    element.querySelectorAll('[data-js-call-center-closed]:not([data-js-cta-swap-exclude])').forEach((ctaWrapper) => {
      const replaceAll = Object.hasOwnProperty.call(ctaWrapper.dataset, 'jsCtaReplaceAll')
      updateCTA(ctaWrapper.querySelector('a'), ctaWrapper, replaceAll, ctaConfig, AFTER_HOURS)
    })
  }

  function updateModuleCTAs (module) {
    const moduleName = module.dataset.jsModuleName || 'default'
    if (module.dataset.jsCtaSwapExempt === 'true') {
      return
    }
    if (Object.hasOwnProperty.call(ctaConfigMap, moduleName)) {
      findCTAs(module, ctaConfigMap[moduleName])
      return
    }
    if (ctaConfigMap.default) { // If no default config is set, modules without their own config are unchanged
      findCTAs(module, ctaConfigMap.default)
    }
  }

  function updateCTA (cta, ctaWrapper, replaceAll, ctaConfigs, hours) {
    if (!cta) return
    if (replaceAll) { ctaWrapper.innerHTML = '' }
    ctaConfigs.forEach((ctaConfig, configIndex) => {
      const { duringHoursURL, duringHoursText, duringHoursStyle, duringHoursButtonIcon, afterHoursURL, afterHoursText, afterHoursStyle, afterHoursButtonIcon } = ctaConfig
      const isDuringHours = hours === DURING_HOURS
      const hoursConfig = {
        url: isDuringHours ? duringHoursURL : afterHoursURL,
        text: isDuringHours ? duringHoursText : afterHoursText,
        buttonStyle: isDuringHours ? duringHoursStyle : afterHoursStyle,
        buttonIcon: isDuringHours ? duringHoursButtonIcon : afterHoursButtonIcon
      }
      if (!hoursConfig.url && !hoursConfig.text) { // If url and text are empty, remove the CTA
        configIndex === 0 && cta.remove() // Only first cta needs removed, early return prevents adding latter ones
        return
      }
      ctaWrapper.appendChild(buildCTA(cta, hoursConfig, replaceAll, configIndex))
    })
  }

  function buildCTA (cta, { url, text, buttonStyle, buttonIcon }, replaceAll, idx) {
    const hasIcon = buttonIcon && buttonIcon !== 'none'
    const hasButtonStyle = buttonStyle && (cta.className.includes('btn') || replaceAll)
    const currentCTAClasses = [...cta.classList].filter((cls) => !cls.includes('btn-')) // Filter out button style as this will be added later
    const replaceAllClasses = replaceAll ? currentCTAClasses : []
    const currentIcon = cta.querySelector('svg')
    const iconSizeClass = currentIcon ? [...currentIcon.classList].filter((cls) => cls.includes('h-')) : 'h-5'
    let newCTA = replaceAll ? document.createElement('a') : cta

    if (idx > 0 && !replaceAll) { newCTA = cta.cloneNode() } // For added CTAs, clone the existing one to match classNames
    newCTA.href = replacePhonePlaceholder(url)
    newCTA.innerHTML = replacePhonePlaceholder(text)
    if (hasButtonStyle) {
      newCTA.className = [...newCTA.classList].filter((cls) => !/btn-\w{3,}/.test(cls)).join(' ') // Remove existing btn-style class name
      newCTA.classList.add(buttonStyle, ...replaceAllClasses)
    }
    hasIcon && fetchIcon(buttonIcon).then(icon => {
      if (!icon) return
      newCTA.innerHTML = icon + newCTA.innerText
      newCTA.querySelector('svg').classList.add(iconSizeClass, 'mr-sm', 'fill-current', 'flex-no-shrink')
    })

    return newCTA
  }

  function fetchIcon (buttonIcon) {
    return fetch(cdn(`/images/svg/${buttonIcon}.svg`))
      .then(r => {
        if (!r.ok) { throw new Error('Icon not found') }
        return r.text()
      })
      .catch(err => console.error(err))
  }
}
